import { getDifferenceInMinutes } from '@/utils/date/date';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DetailDisplayItem from './DetailDisplayItem';

export type DisplayDiffDownTimeProps = {
  startAt?: Date | string;
  endAt?: Date | string;
};

const DisplayDiffDownTime: FC<DisplayDiffDownTimeProps> = ({
  startAt,
  endAt,
}: DisplayDiffDownTimeProps) => {
  const { t } = useTranslation();

  const downTime = useMemo(() => {
    if (!startAt || !endAt) return '';
    const startAtDate = new Date(startAt);
    const endAtDate = new Date(endAt);
    return `${getDifferenceInMinutes(startAtDate, endAtDate)}${t('date.minutes')}`;
  }, [startAt, endAt, t]);

  if (!downTime) return null;

  return <DetailDisplayItem label={t('date.down-time')} value={downTime} />;
};

export default DisplayDiffDownTime;
